import { Link } from "react-router-dom";
import { useQuery } from "../../utils/hooks";
import { useTranslation } from 'react-i18next';
export default function Sidebar(props) {
  const { i18n } = useTranslation();
  const query = useQuery();
  return (
    <div className="fixed h-screen z-10 w-screen md:w-56 lg:w-96 overflow-y-auto bg-gray-100 pt-10">
      <ul className="flex flex-col mx-8 space-y-6 text-lg items-start">
        {props.tags.map((item, index) => (
          <li key={index} className="flex flex-row w-full">
            <Link
              to={`/your-timetables?tagId=${item.uuid}`}
              className={`flex-grow ${query.get("tagId") === item.uuid ? "text-orange" : "hover:text-orange"}`}
            >
              {item.name}
            </Link>
          </li>
        ))}
      </ul>
      <p className="absolute bottom-16 text-xl w-full text-center hover:text-orange">
        <Link to="/your-timetables">{i18n.t("all-timetables")} →</Link>
      </p>
    </div>
  );
}
