import React from "react";
import Navbar from "./Navbar";
import { Redirect } from "react-router";
import LoadingBar from "react-top-loading-bar";
export default function PageLayout(props) {
  return (
    <>
      {props.signedIn ? (
        <>
          <header>
            <Navbar setSignedIn={props.setSignedIn} addMessage={props.addMessage}/>
            <LoadingBar color="#D87722" height={3} ref={props.loadingBar} />
          </header>
          <main className="mt-14 bg-gray-400 " style={{ minHeight: "calc(100vh - 56px)" }}>
            {props.children}
          </main>
        </>
      ) : (
        <Redirect to="/signin" />
      )}
    </>
  );
}
