import React from "react";
import i18n from "i18next";

function useOutsideDismiss(ref, callback) {
  React.useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        callback(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, callback]);
}

export default function Select(props) {
  const [showDropdown, setShowDropdown] = React.useState(false);
  const [placeholder, setPlaceholder] = React.useState(props.placeholder);
  const [newTagName, setNewTagName] = React.useState("");

  React.useEffect(() => {
    setPlaceholder(props.placeholder);
  }, [props.placeholder]);

  const wrapperRef = React.useRef(null);
  useOutsideDismiss(wrapperRef, setShowDropdown);
  return (
    <div className="relative" ref={wrapperRef}>
      <button
        className={`relative h-7 w-full border bg-gray-100 text-left pl-2 pr-5 text-gray-500 focus:outline-none overflow-hidden border-${
          showDropdown ? "orange " : "gray-400"
        }`}
        onClick={() => setShowDropdown(!showDropdown)}
        style={{ borderRadius: showDropdown ? "6px 6px 0 0" : "6px" }}
      >
        {props.languageSupported? i18n.t(placeholder) : placeholder}
        <i className={`absolute right-1 top-1.5 t-arrow-${showDropdown ? "up" : "down"}`} />
      </button>
      {showDropdown && (
        <div
          className="flex flex-col w-full shadow-xl absolute z-20 max-h-40 overflow-y-auto bg-white border border-gray-400 divide-y divide-gray-200"
          style={{ borderRadius: "0 0 6px 6px" }}
        >
          {props.allowModify && (
            <form
              className="relative min-h-7 border border-transparent text-left pl-2 text-gray-500 hover:border-orange"
              onSubmit={(e) => {
                e.preventDefault();
                props.onAdd(newTagName, props.parentTag);
                setNewTagName("");
              }}
            >
              <input
                type="text"
                value={newTagName}
                className="bg-transparent focus:outline-none w-full pr-4 placeholder-gray-200"
                placeholder="Add category"
                onChange={(event) => setNewTagName(event.target.value)}
              />
              <button type="submit" className="absolute right-1 top-1/2 transform -translate-y-1/2 focus:outline-none">
                <i className="t-plus inline-block w-min" />
              </button>
            </form>
          )}
          {props.data.map((tag, index) => (
            <div
              key={tag.uuid? tag.uuid: index}
              className="relative min-h-7 text-left pl-2 pr-5 text-gray-300 hover:bg-gray-100 cursor-pointer"
              onClick={() => {
                props.setValue(tag);
                setPlaceholder(tag.name);
                setShowDropdown(false);
              }}
            >
              <p>{props.languageSupported? i18n.t(tag.name) : tag.name}</p>
              {props.allowModify && (
                <button className="absolute right-1 top-1/2 transform -translate-y-1/2 focus:outline-none hover:text-gray-200">
                  <i
                    className="t-trash-can"
                    onClick={(e) => {
                      e.stopPropagation();
                      console.log("delete");
                      props.onDelete(tag, props.parentTag);
                    }}
                  />
                </button>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
