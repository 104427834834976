// @flow
import * as React from "react";

type Header = {
  key: string,
  name: string,
};

type Row = {
  [key: string]: string | number,
};

type Props = {
  headers: Header[],
  data: Row[],
};

export default function Table(props: Props): React.Node {
  return (
    <div className="table w-full border-2 text-white text-center rounded-3xl overflow-hidden font-body">
      <div className="table-row-group">
        <div className="table-row rounded-3xl">
          {props.headers.map((head, index) => (
            <div
              className={`table-cell px-3 py-5 bg-orange border-b-2 ${index !== 0 ? "border-l-2" : ""}`}
              key={"head" + index}
            >
              {head.name}
            </div>
          ))}
        </div>
        {props.data.map((row, index) => (
          <div className="table-row" key={"row" + index}>
            {props.headers.map((head, index) => (
              <div className={`table-cell px-3 py-5 ${index !== 0 ? "border-l-2" : ""}`} key={"row + head" + index}>
                {row[head.key]}
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
}
