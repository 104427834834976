// @flow
import * as React from "react";
import Sidebar from "./Sidebar";
import Selection from "./Selection";
import type { Tag } from "./types";
import PageLayout from "../Shared/PageLayout";
import useMediaQuery from "use-mediaquery";
import Breakpoints from "../../utils/Breakpoints";

type Props = {
  signedIn: boolean,
  setSignedIn: (boolean) => any,
};

export default function Home(props: Props): React.Node {
  // region saved-tags
  const [savedTags, setSavedTags] = React.useState([]);
  (savedTags: Tag[]);
  // load tags
  React.useEffect(() => {
    const tt = localStorage.getItem("savedTags");
    if (tt == null) return;
    setSavedTags(JSON.parse(tt));
  }, []);
  // store tags
  React.useEffect(() => {
    localStorage.setItem("savedTags", JSON.stringify(savedTags));
  }, [savedTags]);
  // endregion
  const [showSidebar, setShowSidebar] = React.useState(false);
  const breakpointMd = useMediaQuery(Breakpoints.md);
  return (
    <PageLayout signedIn={props.signedIn} setSignedIn={props.setSignedIn} loadingBar={props.loadingBar} addMessage={props.addMessage}>
      <section className="h-full mt-14">
        <aside>
          {(showSidebar || breakpointMd) && (
            <Sidebar tags={savedTags} setTags={setSavedTags} setShow={setShowSidebar} />
          )}
        </aside>
        <aside className="md:hidden">
          <button
            className={`fixed rounded-full bottom-2 focus:outline-none shadow-lg right-2 flex items-center p-3 text-xl z-30 outline-none bg-${showSidebar? "orange" : "white"} text-${showSidebar? "white" : "gray-400"}`}
            onClick={() => setShowSidebar(!showSidebar)}
          >
            {showSidebar? <i className="t-esc"/>: <i className="t-saved_timetables"/> }
          </button>
        </aside>
        <article className="md:ml-56 lg:ml-96 pt-4 h-full">
          <Selection tags={savedTags} setTags={setSavedTags} loadingBar={props.loadingBar} addMessage={props.addMessage}/>
        </article>
      </section>
    </PageLayout>
  );
}
