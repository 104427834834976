// @flow
import type { Tag, TClass, Timetable } from "../components/YourTimetable/types";
import firebase from "firebase";
import axios from "axios";
import { API_URL } from "./Globals";
import { AxiosResponse } from "axios";

type ShorthandResponse = {
  shorthands: Tag[],
  class: ?TClass,
};

export async function getTimetable(tag_uuid: string): Promise<AxiosResponse<Timetable> | void> {
    const token = await firebase.auth().currentUser.getIdToken();
    const response = await axios.get(`${API_URL}/timetable?selectionIds=${tag_uuid}`, {
      headers: {
        Authorization: token,
        AcceptLanguage: localStorage.getItem("language") ? JSON.parse(localStorage.getItem("language")).shorthand : "ro",
      },
    });
    return response;
}

export async function getChildrenTags(tag_uuid: string): Promise<AxiosResponse<Tag[]> | void> {
    const token = await firebase.auth().currentUser.getIdToken();
    const response = await axios.get(`${API_URL}/tags?tagId=${tag_uuid}`, {
      headers: {
        Authorization: token,
        AcceptLanguage: localStorage.getItem("language") ? JSON.parse(localStorage.getItem("language")).shorthand : "ro",
      },
    });
    return response;
}

export async function getShorthands(selectionId: string): Promise<AxiosResponse<ShorthandResponse> | void> {
    const token = await firebase.auth().currentUser.getIdToken();
    const response = await axios.get(`${API_URL}/shorthands?selectionId=${selectionId}`, {
      headers: {
        Authorization: token,
        AcceptLanguage: localStorage.getItem("language") ? JSON.parse(localStorage.getItem("language")).shorthand : "ro",
      },
    });
    return response;
}

export async function uploadTimetable(tag_uuid: string, data: Timetable) {
    const token = await firebase.auth().currentUser.getIdToken();
    const response = await axios.post(`${API_URL}/timetable?tagId=${tag_uuid}`, data, {
      headers: {
        Authorization: token,
        AcceptLanguage: localStorage.getItem("language") ? JSON.parse(localStorage.getItem("language")).shorthand : "ro",
      },
    });
    return response;
}

export async function getPreview(tag, data) {
    const token = await firebase.auth().currentUser.getIdToken();

    const response = await axios.post(`${API_URL}/timetable/preview?tagId=${tag.uuid}`, data, {
      headers: {
        Authorization: token,
        "accept-language": localStorage.getItem("language")
          ? JSON.parse(localStorage.getItem("language")).shorthand
          : "ro",
      },
    });
    return response;
}

export async function deleteTag(tag) {
    const token = await firebase.auth().currentUser.getIdToken();
    const response = await axios.delete(`${API_URL}/tag?tagId=${tag.uuid}`, {
      headers: {
        Authorization: token,
        AcceptLanguage: localStorage.getItem("language") ? JSON.parse(localStorage.getItem("language")).shorthand : "ro",
      },
    });

    return response;
}

export async function uploadTag(tag, parentTag) {
  try {
    const token = await firebase.auth().currentUser.getIdToken();
    const response = await axios.post(`${API_URL}/tag?tagId=${parentTag.uuid}`, tag, {
      headers: {
        Authorization: token,
      },
    });

    return response;
  } catch (e) {
    return null;
  }
}
