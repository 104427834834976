import React from "react";
import Sidebar from "./Sidebar";
import PageLayout from "../Shared/PageLayout";
import Presentation from "./Presentation";
export default function Home(props) {
  // if (props.signedIn)
  const [savedTags, setSavedTags] = React.useState([]);
  // load tags
  React.useEffect(() => {
    const tt = localStorage.getItem("savedTags");
    if (tt == null) return;
    setSavedTags(JSON.parse(tt));
  }, []);
  // store tags
  React.useEffect(() => {
    localStorage.setItem("savedTags", JSON.stringify(savedTags));
  }, [savedTags]);
  return (
    <>
      {props.signedIn ? (
        <PageLayout signedIn={props.signedIn} setSignedIn={props.setSignedIn} loadingBar={props.loadingBar} addMessage={props.addMessage}>
          <aside>
          <Sidebar tags={savedTags} setTags={setSavedTags}/>
          </aside>
          <section className="ml-96 mt-14 bg-gray-400 " style={{ height: "calc(100vh - 56px)" }} />
        </PageLayout>
      ) : (
        <Presentation />
      )}
    </>
  );
}
