import React from "react";
import UploadPopup from "./UploadPopup";
import firebase from "firebase/app";
import Table from "../Shared/Table";
import { uploadTimetable } from "../../utils/endpoints";
import PageLayout from "../Shared/PageLayout";
import { getChildrenTags } from "../../utils/endpoints";
import { useTranslation } from "react-i18next";
import { getPreview } from "../../utils/endpoints";

function InnerTable({ index, headers, value }) {
  console.log(value);
  return (
    <>
      <p className="text-xl">Week {index + 1}</p>
      <Table headers={headers} data={value} />
    </>
  );
}

export default function CreateTimetable(props) {
  const [uploaded, setUploaded] = React.useState(false);
  const [showUploadPopUp, setShowUploadPopUp] = React.useState(false);
  const [file, setFile] = React.useState(null);
  const [initalTags, setInitalTags] = React.useState([]);
  const [tags, setTags] = React.useState([{ options: initalTags, selected: null, modify: false }]);
  const [timetable, setTimetable] = React.useState({});
  const { i18n } = useTranslation();
  const headers = [
    { key: "day", name: "Day" },
    { key: "start", name: "Start Hour" },
    { key: "duration", name: "Duration" },
    { key: "teacher", name: "Teacher" },
    { key: "subject", name: "Subject" },
    { key: "type", name: "Type" },
  ];

  React.useEffect(() => {
    async function getInitialTags() {
      props.loadingBar.current.continuousStart();
      getChildrenTags("")
        .then((response) => setInitalTags(response.data))
        .catch((error) => error.response.data.errors.forEach((e) => props.addMessage({ text: e, type: "error" })))
        .finally(() => props.loadingBar.current.complete());
    }
    let auth = firebase.auth();
    if (auth.currentUser != null) {
      getInitialTags();
      return;
    }
    auth.onAuthStateChanged(() => getInitialTags());
  }, []);

  React.useEffect(() => {
    if (showUploadPopUp) {
      setFile(null);
      setTags([{ options: initalTags, selected: null, modify: false }]);
      setUploaded(false);
      setTimetable({});
    } else if (!showUploadPopUp && uploaded) fetchData();
  }, [showUploadPopUp, uploaded, initalTags]);

  React.useEffect(() => {
    if (showUploadPopUp) document.getElementsByTagName("BODY")[0].style.overflowY = "hidden";
    else document.getElementsByTagName("BODY")[0].style.overflowY = "auto";
  }, [showUploadPopUp]);

  async function fetchData() {
    props.loadingBar.current.continuousStart();
    const tag = tags[tags.length - 1].selected ? tags[tags.length - 1].selected : tags[tags.length - 2].selected;
    const formData = new FormData();
    formData.append("table", file, file.name);
    if (tag === null) setTimetable({});
    getPreview(tag, formData)
      .then((response) => setTimetable(response.data))
      .catch((error) => error.response.data.errors.forEach((e) => props.addMessage({ text: e, type: "error" })))
      .finally(()=> props.loadingBar.current.complete());
  }

  const table = React.useMemo(() => {
    if (timetable == null || timetable.weeks == null) return [];
    const y = timetable.weeks.map((w) => {
      const order = ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"];
      const x = order.map((day) => w[day]);
      const classes = x.flat();
      return classes.map((cl) => {
        if (cl) {
          let res = Object.assign({}, cl);
          let date = new Date(cl.start * 1000);
          res.start = date.toISOString().substr(11, 8);
          res.duration = cl.duration / (60 * 60) + " hours";
          return res;
        } else return {};
      });
    });
    return y;
  }, [timetable]);

  function handleUpload(event) {
    event.preventDefault();
    const tag = tags[tags.length - 1].selected ? tags[tags.length - 1].selected : tags[tags.length - 2].selected;
    uploadTimetable(tag.uuid, timetable).then((response) => {
      if (response === null) props.addMessage({ text: "Timetable could not be uploaded", type: "error" });
      else if (response && response.status === 201)
        props.addMessage({ text: i18n.t("timetable-uploaded"), type: "success" });
    });
  }

  function setFileIfValid(file) {
    if(file === undefined)
      return;
    
    if (file && (file.type === "application/json" || file.name.split(".").pop() === "csv")) setFile(file);
    else
      props.addMessage({
        text: "Invalid file type",
        type: "error",
      });
  }
  return (
    <PageLayout signedIn={props.signedIn} setSignedIn={props.setSignedIn} loadingBar={props.loadingBar} addMessage={props.addMessage}>
      <section>
        <article className="flex flex-col md:flex-row">
          <div className=" text-white w-full p-2 md:p-4 lg:p-10">
            {table.length === 0 ? (
              <div className="md:w-2/3 flex justify-center" style={{ minHeight: "calc(60vh - 56px)" }}>
                <p className="text-3xl md:text-5xl self-center">{i18n.t("await-preview")}</p>
              </div>
            ) : (
              <div className="md:w-2/3 overflow-auto">
                {table.map((value, index) => (
                  <InnerTable value={value} index={index} headers={headers} key={"t" + index} />
                ))}
              </div>
            )}
          </div>
          <div className="md:fixed md:left-3/4 flex flex-col space-y-4 mx-auto md:top-1/2 transform md:-translate-y-1/2">
            <button
              className="truncate bg-white px-8 py-3 rounded-3xl h-12 focus:outline-none "
              onClick={() => setShowUploadPopUp(true)}
            >
              {i18n.t("upload-timetable")}
            </button>
            <button
              className="truncate bg-orange px-8 py-3 rounded-3xl h-12 focus:outline-none text-white"
              disabled={!uploaded}
              style={{
                opacity: uploaded ? "100%" : "50%",
                cursor: uploaded ? "pointer" : "auto",
              }}
              onClick={handleUpload}
            >
              {i18n.t("finish-upload")}
            </button>
          </div>
          {showUploadPopUp && (
            <UploadPopup
              setShow={setShowUploadPopUp}
              setFile={setFileIfValid}
              file={file}
              setTags={setTags}
              tags={tags}
              setUploaded={setUploaded}
              addMessage={props.addMessage}
              loadingBar={props.loadingBar}
            />
          )}
        </article>
      </section>
    </PageLayout>
  );
}
