import React from "react";
import i18n from "i18next"
export default function DropSection(props) {
  const drop = React.useRef(null);
  const [dragging, setDragging] = React.useState(false);

  React.useEffect(() => {
    drop.current.addEventListener("dragover", handleDragOver);
    drop.current.addEventListener("drop", handleDrop);
    drop.current.addEventListener("dragenter", handleDragEnter);
    drop.current.addEventListener("dragleave", handleDragLeave);

    return () => {
      if (drop.current)
        drop.current.removeEventListener("dragover", handleDragOver);
      if (drop.current) drop.current.removeEventListener("drop", handleDrop);
      if (drop.current)
        drop.current.removeEventListener("dragenter", handleDragEnter);
      if (drop.current)
        drop.current.removeEventListener("dragleave", handleDragLeave);
    };
  }, []);

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(true);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const { files } = e.dataTransfer;

    if (files && files.length) {
      props.setFile(files[0]);
    }
    setDragging(false);
  };

  const handleDragEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();
    
  };
  
  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(false);
  };

  return (
    <div
      className={`relative border-2 h-full border-dashed rounded-xl text-gray-300 text-center bg-${dragging? "gray-100" : "white"}`}
      ref={drop}
    >
      <p className="absolute top-1/2 left-1/2" style={{transform:"translate(-50%, -50%)"}}>{ props.file!==null? props.file.name : i18n.t("drag-file") }</p>
    </div>
  );
}
