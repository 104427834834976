import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import firebase from "firebase/app";
import "firebase/auth";
import { BrowserRouter } from "react-router-dom";
import "./i18n.js";
var config = {
  apiKey: "AIzaSyAq34RaM3GYLLJObAGC0bnvU-jQAk11xCc",
  projectId: "timetable-306307",
  authDomain: "auth.timetable.qubuilder.com",
};
firebase.initializeApp(config);

ReactDOM.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
