import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translationEN from "./translations/en/translation.json"
import translationRO from "./translations/ro/translation.json"

// the translations
// (tip move them in a JSON file and import them)
const resources = {
  en: {
    translation: translationEN
  },
  ro: {
    translation: translationRO
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: localStorage.getItem("language")
    ? JSON.parse(localStorage.getItem("language")).shorthand
    : "ro",

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
