import React from "react";
import firebase from "firebase/app";
import { useTranslation } from 'react-i18next';

export default function SignInFormBox(props) {
  const { i18n } = useTranslation();
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  function handleSignIn(event) {
    event.preventDefault();
    firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then((userCredential) => {
        console.log(userCredential);
        props.setSignedIn(true);
      })
      .catch((error) => {
        console.log(error);
        props.addMessage({
          text: "Invalid user or password",
          type: "error",
        })
      });
  }

  function handleSignInWithGoogle() {
    const provider = new firebase.auth.GoogleAuthProvider();
    firebase
      .auth()
      .signInWithPopup(provider)
      .then((result) => {
        /** @type {firebase.auth.OAuthCredential} */
        console.log(result);
        props.setSignedIn(true);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  return (
    <div className="bg-orange p-8 rounded-xl shadow-2xl flex flex-col space-y-14">
      <h1 className="text-center text-white text-2xl">{i18n.t("sign-in")}</h1>
      <form className="flex flex-col space-y-12" onSubmit={handleSignIn}>
        <div>
          <label className="text-white">{i18n.t("email")}</label>
          <input
            type="email"
            placeholder={i18n.t("email")}
            className="bg-white rounded-2xl focus:outline-none h-8 px-2 shadow-xl block w-full"
            onChange={(event) => setEmail(event.target.value)}
          />
        </div>
        <div>
          <label className="text-white">{i18n.t("password")}</label>
          <input
            type="password"
            placeholder={i18n.t("password")}
            className="bg-white rounded-2xl focus:outline-none h-8 px-2 shadow-xl block w-full"
            onChange={(event) => setPassword(event.target.value)}
          />
        </div>
        <button
          type="submit"
          className="bg-white w-28 h-8 mx-auto rounded-2xl focus:outline-none"
        >
          {i18n.t("sign-in")}
        </button>
      </form>
      {/*<button*/}
      {/*  className="bg-white w-48 h-8 mx-auto rounded-2xl flex align-middle justify-center pt-1 space-x-2 focus:outline-none"*/}
      {/*  onClick={handleSignInWithGoogle}*/}
      {/*>*/}
      {/*  <img*/}
      {/*    src="images/google.svg"*/}
      {/*    alt="google"*/}
      {/*    draggable={false}*/}
      {/*    className="w-6"*/}
      {/*  />*/}
      {/*  <p>Sign in with google</p>*/}
      {/*</button>*/}
    </div>
  );
}
