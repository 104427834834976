import SignInPage from "./components/SignIn";
import { Route, Switch } from "react-router-dom";
import React from "react";
import firebase from "firebase/app";
import Home from "./components/Home";
import CreateTimetable from "./components/CreateTimetable";
import YourTimetable from "./components/YourTimetable/index.jsx";
import MessageBox from "./components/Shared/MessageBox";

function App() {
  const [signedIn, setSignedIn] = React.useState(true);
  firebase.auth().onAuthStateChanged((user) => {
    if (user === null) setSignedIn(false);
  });
  const loadingBar = React.useRef(null);
  const [messageList, setMessageList] = React.useState([]);

  function equalsMessage(message1, message2) {
    if(message1.text === message2.text && message1.type === message2.type) return true;

    return false;
  }

  function handleMessageDismiss(messageDismissed) {
    setMessageList(messageList.filter((message)=> !equalsMessage(message, messageDismissed)));
  }

  function checkInclude(message){
    for (let i=0; i<messageList.length; i++) {
      if(equalsMessage(messageList[i], message))
        return true;
    }

    return false;
  }

  function addMessage(message) {
    if(!checkInclude(message))
      setMessageList([...messageList, message]);
  }
  return (
    <>
      <Switch>
        <Route path="/signin">
          <SignInPage signedIn={signedIn} setSignedIn={setSignedIn} loadingBar={loadingBar} addMessage={addMessage}/>
        </Route>
        <Route path="/create-timetable">
          <CreateTimetable signedIn={signedIn} setSignedIn={setSignedIn} loadingBar={loadingBar} addMessage={addMessage}/>
        </Route>
        <Route path="/your-timetables">
          <YourTimetable signedIn={signedIn} setSignedIn={setSignedIn} loadingBar={loadingBar} addMessage={addMessage}/>
        </Route>
        <Route path="/">
          <Home signedIn={signedIn} setSignedIn={setSignedIn} loadingBar={loadingBar} addMessage={addMessage}/>
        </Route>
      </Switch>
      {messageList.map((message, index) =>
        <MessageBox key={index} message={message} index={index} onClose={handleMessageDismiss}>
          {message.text}
        </MessageBox>
      )}
    </>
  );
}

export default App;
