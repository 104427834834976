import React from "react";
import { Link, NavLink } from "react-router-dom";
import firebase from "firebase/app";
import useMediaQuery from "use-mediaquery";
import Breakpoints from "../../utils/Breakpoints";
import SettingsPopUp from "./SettingsPopUp";
import { useTranslation } from 'react-i18next';

const UserOptions = (props) => {
  function useOutsideDismiss(ref) {
    React.useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target) && event.target.id !== "user-option-btn") {
          props.setShowUserOptions(false);
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  const wrapperRef = React.useRef(null);
  useOutsideDismiss(wrapperRef);
  return (
    <div
      className="flex flex-col w-56 fixed right-0 top-14 z-50 bg-white mt-0.5"
      style={{ borderRadius: "13px 0px 13px 13px" }}
      ref={wrapperRef}
    >
      {props.userOptions.map((option, index) => {
        const style = React.createRef({});
        if (index === 0) style.current = { borderRadius: "13px 0px 0px 0px" };
        else if (index === props.userOptions.length - 1) style.current = { borderRadius: "0px 0px 13px 13px" };

        return (
          <button
            key={index}
            className="hover:bg-gray-100 text-left p-2 focus:outline-none"
            style={style.current}
            onClick={() => {
              if (option.callback != null) option.callback();
            }}
          >
            {option.name}
          </button>
        );
      })}
    </div>
  );
};

const Menu = (props) => {
  function useOutsideDismiss(ref) {
    React.useEffect(() => {
      function handleClickOutside(event) {
        if (
          ref.current &&
          !ref.current.contains(event.target) &&
          event.target.id !== "menu-btn" &&
          event.target.id !== "menu-icon"
        ) {
          props.setShowMenu(false);
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  const wrapperRef = React.useRef(null);
  useOutsideDismiss(wrapperRef);
  return (
    <div className="bg-white w-screen z-20 fixed top-14" ref={wrapperRef}>
      <ul className="flex flex-col space-y-4 mx-8 my-6">
        <props.Links />
      </ul>
    </div>
  );
};

export default function Navbar(props) {
  const [showUserOptions, setShowUserOptions] = React.useState(false);
  const [showMenu, setShowMenu] = React.useState(false);
  const [showSettings, setShowSettings] = React.useState(false);
  const [userInitials, setUserInitials] = React.useState("");
  const { i18n } = useTranslation();
  function handleSignOut() {
    firebase.auth().signOut();
    props.setSignedIn(false);
  }

  function openSettings() {
    setShowSettings(true);
    setShowUserOptions(false);
  }
  const userOptions = [
    { name: i18n.t("settings"), callback: openSettings },
    { name: i18n.t("sign-out"), callback: handleSignOut },
  ];

  const menuLinks = [
    { text: i18n.t("your-timetables"), link: "/your-timetables", icon: "t-your_schedules"},
    { text: i18n.t("create-timetable"), link: "/create-timetable", icon: "t-create_schedule"},
  ];
  const Links = () =>
    menuLinks.map((link, index) => (
      <NavLink activeClassName="text-orange" to={link.link} key={index}>
        <button className="focus:outline-none hover:text-orange flex flex-nowrap">
          <p className={`${link.icon} block text-xl mr-1`} />
          <p className="mt-0.5 whitespace-nowrap">{link.text}</p>
        </button>
      </NavLink>
    ));

  firebase.auth().onAuthStateChanged((user) => {
    if (user)
      setUserInitials(
        user.displayName
          .split(" ")
          .filter((word, index) => word && index < 3)
          .map((word) => word.charAt(0).toUpperCase())
          .join("")
      );
  });

  React.useEffect(() => {
    if (showSettings) document.getElementsByTagName("BODY")[0].style.overflowY = "hidden";
    else document.getElementsByTagName("BODY")[0].style.overflowY = "auto";
  }, [showSettings]);

  const breakpoint = useMediaQuery(Breakpoints.md);
  return (
    <>
      <div className="fixed px-8 py-1 mx-auto w-screen z-10 bg-white top-0">
        <nav className="flex justify-center md:justify-start w-full md:space-x-28">
          {!breakpoint && (
            <button
              className="absolute top-1/2 transform -translate-y-1/2 left-6 focus:outline-none text-gray-300 text-3xl my-auto"
              id="menu-btn"
              onClick={() => setShowMenu(!showMenu)}
            >
              <i id="menu-icon" className={showMenu ? "t-esc" : "t-menu "} />
            </button>
          )}
          <div className="flex md:space-x-10 lg:space-x-28">
            <Link to="/">
              <img src="/images/qub_timetable_logo.svg" alt="qubuilder" className="h-12" />
            </Link>
            <ul className="hidden md:flex flex-row my-auto md:space-x-4 lg:space-x-16">
              <Links />
            </ul>
          </div>
          <button
            className={`absolute top-1/2 transform -translate-y-1/2 right-6 w-10 h-10 text-lg text-white font-bold rounded-full bg-gray-300 my-auto focus:outline-none border-4 border-${
              showUserOptions ? "gray-100" : "transparent"
            }`}
            onClick={() => setShowUserOptions(!showUserOptions)}
            id="user-option-btn"
          >
            {userInitials}
          </button>
        </nav>
      </div>
      {showUserOptions && <UserOptions userOptions={userOptions} setShowUserOptions={setShowUserOptions} />}
      {!breakpoint && showMenu && <Menu Links={Links} setShowMenu={setShowMenu} />}
      {showSettings && <SettingsPopUp setShow={setShowSettings} addMessage={props.addMessage}/>}
    </>
  );
}
