import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
export default function Presentation() {
  const { i18n } = useTranslation();
  return (
    <div
      className="object-cover w-screen"
      style={{
        backgroundImage: `url(images/background-welcome.png?nf_resize=fit&w=${window.screen.availWidth})`,
        height: window.innerHeight,
      }}
    >
      <div className="fixed top-0 h-screen w-screen bg-gray-100 opacity-70" />
      <header>
        <nav className="absolute w-screen top-0 px-6 lg:px-32 py-4 flex flex-row justify-between">
          <img src="/images/qub_timetable_logo.svg" alt="qubuilder" className="h-12" />
          <Link className="text-lg hover:text-orange mt-3" to="/signin">
            <h3>{i18n.t("sign-in")}</h3>
          </Link>
        </nav>
      </header>
      <main>
        <section className="absolute md:pt-6 lg:pt-0 top-1/2 transform -translate-y-1/2 px-6 lg:px-32 md:w-2/3 w-full lg:w-1/2 space-y-4 lg:space-y-8 text-gray-400 text-md md:text-sm lg:text-md">
          <h1 className="text-4xl lg:text-6xl">qubtimetable</h1>
          <p className="text-md lg:text-lg">{i18n.t("qubtimetable-description")}</p>
          <div className="flex justify-center md:justify-start h-12 space-x-3">
            <a href="https://qub.link/timetable-android" rel="noreferrer" target="_blank">
              <img src="images/google_play.svg" alt="google-play" className="h-12"/>
            </a>
            <a href="https://qub.link/timetable-windows" rel="noreferrer" target="_blank">
              <img src="images/microsoft.svg" alt="microsoft-store"className="h-12"/>
            </a>
          </div>
        </section>
      </main>
    </div>
  );
}
