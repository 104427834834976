import React from "react";
import useMediaQuery from "use-mediaquery";
import Breakpoints from "../../utils/Breakpoints";
import Select from "../Shared/Select";
import { useTranslation } from "react-i18next";

export default function SettingsPopUp(props) {
  const breakpoint = useMediaQuery(Breakpoints.lg);
  const changed = React.useRef(false);
  const { i18n } = useTranslation();
  const languages = [
    { name: "english", shorthand: "en" },
    { name: "romanian", shorthand: "ro" },
  ];
  const [language, setLanguage] = React.useState(
    localStorage.getItem("language")
      ? JSON.parse(localStorage.getItem("language"))
      : { name: i18n.t("romanian"), shorthand: "ro" }
  );

  function handleSave() {
    try {
      i18n
        .changeLanguage(language.shorthand, (err, t) => {
          if (err) props.setMessageBox({ visible: true, text: err, type: "error" });
          t("key");
        })
        .then(() => {
          console.log(language);
          localStorage.setItem("language", JSON.stringify(language));
          props.addMessage({
            text: i18n.t("settings-saved"),
            type: "success",
          });
        });
    } catch (e) {
      props.addMessage({
        text: i18n.t("could-not-save-settings"),
        type: "error",
      });
    }
  }

  function handleChangeLanguage(language) {
    setLanguage(language);
    changed.current = true;
  }

  return (
    <>
      <div
        className="hidden lg:block fixed top-0 h-screen w-screen bg-black opacity-50 z-40 "
        onClick={() => props.setShow(false)}
      />
      <section
        className="fixed bg-white z-40 py-4  px-6 md:px-14 lg:rounded-2xl top-14 lg:top-1/2 lg:left-1/2 transform lg:-translate-x-1/2 lg:-translate-y-1/2 overflow-auto text-gray-300"
        style={{
          width: breakpoint ? "450px" : "100vw",
          height: breakpoint ? "500px" : "calc(100vh - 56px)",
        }}
      >
        <button className="lg:hidden absolute top-1 right-4" onClick={() => props.setShow(false)}>
          <i className="t-esc text-lg" />
        </button>
        <article>
          <h3 className="text-center text-xl border-b border-gray-300 pb-4 mb-7">{i18n.t("settings")}</h3>
          <div className="space-y-2">
            <h4>{i18n.t("language")}</h4>
            <Select
              placeholder={language.name}
              data={languages}
              allowModify={false}
              languageSupported={true}
              setValue={handleChangeLanguage}
            />
          </div>
        </article>
        <button
          className="bg-orange py-2 px-7 absolute bottom-16 lg:bottom-4 left-1/2 transform -translate-x-1/2 focus:outline-none text-white rounded-full"
          onClick={handleSave}
          disabled={!changed.current}
          style={{
            opacity: changed.current ? "100%" : "50%",
            cursor: changed.current ? "pointer" : "auto",
          }}
        >
          {i18n.t("save")}
        </button>
      </section>
    </>
  );
}
