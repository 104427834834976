import React from "react";
import Select from "../Shared/Select";
import DropSection from "./DropSection";
import firebase from "firebase/app";
import useMediaQuery from "use-mediaquery";
import Breakpoints from "../../utils/Breakpoints";
import { useTranslation } from "react-i18next";
import { uploadTag } from "../../utils/endpoints";
import { getChildrenTags } from "../../utils/endpoints";
import { deleteTag } from "../../utils/endpoints";

export default function UploadPopup(props) {
  const modifyFlag = React.useRef(false);
  const fileInputRef = React.useRef(null);
  const userId = firebase.auth().currentUser.uid;
  const { i18n } = useTranslation();

  function checkInclude(data) {
    for (let i = 0; i < props.tags.length; i++) if (JSON.stringify(props.tags[i]) === JSON.stringify(data)) return true;

    return false;
  }

  function findTag(tag) {
    for (let i = 0; i < props.tags.length; i++)
      if (JSON.stringify(props.tags[i]).includes(JSON.stringify(tag))) return i;
    return -1;
  }

  function updateData(data, index) {
    
    if (data !== undefined && data.length >= 0 && !checkInclude(data)) {
      props.setTags([...props.tags.slice(0, index + 1), { options: data, selected: null, modify: modifyFlag.current }]);
    } else props.setTags(props.tags.slice(0, index + 1));
    
  }

  function getNextTag(tag) {
    
    if (tag.owner && tag.owner === userId) modifyFlag.current = true;
    else modifyFlag.current = false;
    const index = findTag(tag);
    if (props.tags[index].selected === null || props.tags[index].selected.uuid !== tag.uuid) {
      props.loadingBar.current.continuousStart();
      props.tags[index].selected = tag;
      getChildrenTags(tag.uuid)
        .then((response) => updateData(response.data, index))
        .catch((error) => error.response.data.errors.forEach((e) => props.addMessage({ text: e, type: "error" })))
        .finally(()=> props.loadingBar.current.complete());
    }
  }

  function getShortHand(name, index) {
    let shorthand = "";
    name.split(" ").forEach((word) => (shorthand += word.charAt(0).toUpperCase()));
    return shorthand + index;
  }

  async function handleAddTagName(name, parentSelect) {
    if (name === "") {
      props.addMessage({
        text: i18n.t("category-must-not-be-empty"),
        type: "error",
      });
      return;
    }
    props.loadingBar.current.continuousStart();
    let shorthand = getShortHand(name, 0);
    let tag = { shorthand: shorthand, name: name };
    let response = await uploadTag(tag, parentSelect.selected);
    let index = 1;
    while (response === null) {
      shorthand = getShortHand(name, index++);
      tag = { shorthand: shorthand, name: name };
      response = await uploadTag(tag, parentSelect.selected);
    }

    props.addMessage({
      text: i18n.t("category-added"),
      type: "success",
    });

    const Findex = findTag(parentSelect.selected);
    getChildrenTags(parentSelect.selected.uuid)
      .then((response) => {
        const tags = response.data;
        props.setTags([
          ...props.tags.slice(0, Findex + 1),
          { options: tags, selected: null, modify: props.tags[Findex + 1].modify },
        ]);
      })
      .catch((error) => error.response.data.errors.forEach((e) => props.addMessage({ text: e, type: "error" })))
      .finally(()=> props.loadingBar.current.complete());
  }

  async function handleDeleteTag(tag, parentSelect) {
    props.loadingBar.current.continuousStart();
    deleteTag(tag)
      .then(() => {
        const index = findTag(parentSelect.selected);
        
        getChildrenTags(parentSelect.selected.uuid)
          .then((response) => {
            const tags = response.data;
            const tagsAfter =
              props.tags[index + 1].selected === tag ? [] : props.tags.slice(index + 2, props.tags.length);
            props.setTags([
              ...props.tags.slice(0, index + 1),
              { options: tags, selected: null, modify: props.tags[index + 1].modify },
              ...tagsAfter,
            ]);
            props.addMessage({
              text: i18n.t("category-deleted"),
              type: "success",
            });
          })
      })
      .catch((error) => error.response.data.errors.forEach((e) => props.addMessage({ text: e, type: "error" })))
      .finally(()=> props.loadingBar.current.complete());
  }

  const lastCheckedSelect = React.useRef();
  const [checkPreview, setCheckPreview] = React.useState();

  React.useEffect(() => {
    lastCheckedSelect.current =
      props.tags[props.tags.length - 1] && props.tags[props.tags.length - 1].selected
        ? props.tags[props.tags.length - 1]
        : props.tags[props.tags.length - 2];
    setCheckPreview(lastCheckedSelect.current ? lastCheckedSelect.current.modify && props.file : false);
  }, [props.tags[props.tags.length - 1], props.tags, props.file]);

  const breakpoint = useMediaQuery(Breakpoints.lg);

  return (
    <>
      <div
        className="hidden lg:block fixed top-0 h-screen w-screen bg-black opacity-50 z-30"
        onClick={() => props.setShow(false)}
      />
      <section
        className="fixed bg-white z-40 p-10 lg:rounded-2xl top-14 lg:top-1/2 lg:left-1/2 transform lg:-translate-x-1/2 lg:-translate-y-1/2 overflow-auto"
        style={{
          width: breakpoint ? "800px" : "100vw",
          height: breakpoint ? "500px" : "calc(100vh - 56px)",
        }}
      >
        <button className="lg:hidden absolute top-1 right-4" onClick={() => props.setShow(false)}>
          <i className="t-esc text-lg" />
        </button>
        <article className="flex flex-col lg:flex-row lg:space-x-6 h-full">
          <div className="w-full lg:w-1/2">
            <div className="hidden lg:block h-5/6">
              <DropSection setFile={props.setFile} file={props.file} />
            </div>
            <div className="lg:h-1/6 flex justify-center">
              <input
                type="file"
                onChange={(event) => props.setFile(event.target.files[0])}
                className="hidden"
                ref={(fileInput) => (fileInputRef.current = fileInput)}
              />
              <button
                className="bg-gray-100 px-8 py-3 lg:py-1 rounded-3xl focus:outline-none lg:mt-6"
                onClick={() => fileInputRef.current.click()}
              >
                {i18n.t("attach-file")}
              </button>
            </div>
          </div>
          <div className="w-full lg:w-1/2 mt-4 lg:mt-0 relative h-full">
            <div className="flex flex-col space-y-3 lg:h-5/6 lg:overflow-y-auto py-1 ">
              {props.tags.map((data, index) => {
                if ((data.options && data.options.length > 0) || data.modify)
                  return (
                    <Select
                      key={"s" + index}
                      placeholder={data.selected !== null ? data.selected.name : i18n.t("select-category")}
                      data={data.options}
                      setValue={getNextTag}
                      allowModify={data.modify}
                      onAdd={handleAddTagName}
                      onDelete={handleDeleteTag}
                      parentTag={props.tags[index - 1]}
                    />
                  );
                else return <></>;
              })}
            </div>
            <div className="flex lg:h-1/6 justify-center">
              <button
                className="bg-orange px-8 py-3 lg:py-1 rounded-3xl focus:outline-none lg:mt-6 text-white absolute lg:static bottom-8 lg:-bottom-8 "
                disabled={!checkPreview}
                style={{
                  opacity: checkPreview ? "100%" : "50%",
                  cursor: checkPreview ? "pointer" : "auto",
                }}
                onClick={() => {
                  props.setUploaded(true);
                  props.setShow(false);
                }}
              >
                {i18n.t("generate-preview")}
              </button>
            </div>
          </div>
        </article>
      </section>
    </>
  );
}
