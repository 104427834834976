import React from "react";
export default function MessageBox(props) {
  const [bgColor, setBgColor] = React.useState("");
  React.useEffect(() => {
    switch (props.message.type) {
      case "warning":
        setBgColor("bg-yellow");
        break;
      case "error":
        setBgColor("bg-red");
        break;
      case "success":
        setBgColor("bg-green");
        break;
      default:
        setBgColor("bg-white");
    }
  }, [props.message]);

  function useOutsideDismiss(ref) {
    React.useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          props.onClose(props.message);
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  const wrapperRef = React.useRef(null);
  useOutsideDismiss(wrapperRef);

  return (
    <div
      ref={wrapperRef}
      className={`${bgColor} text-white text-lg px-10 text-center z-50 fixed bottom-2 flex justify-center items-center min-h-16 w-5/6 md:w-2/5 lg:w-1/5 transform left-1/2 -translate-x-1/2 rounded-lg shadow-xl}}`}
      style ={{minHeight:64, bottom: 8 + props.index*64}}
    >
      <i className={`absolute left-2 top-1/2 text-2xl transform -translate-y-1/2 focus:outline-none t-${props.message.type}`} />
      <p>{props.message.text}</p>
      <button
        className="absolute right-2 top-1/2 transform -translate-y-1/2 focus:outline-none text-xl"
        onClick={()=>props.onClose(props.message)}
      >
        <i className="t-esc" />
      </button>
    </div>
  );
}
