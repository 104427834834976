// @flow

import * as React from "react";
import Select from "../Shared/Select";
import firebase from "firebase";
import Table from "../Shared/Table";
import type { Tag, TagSelection, TClass, Timetable } from "./types";
import { addTag } from "./util";
import { useQuery } from "../../utils/hooks";
import { getChildrenTags, getShorthands, getTimetable } from "../../utils/endpoints";
import { useTranslation } from "react-i18next";

type Props = {
  tags: Tag[],
  setTags: (any) => any,
};

function InnerTable({ index, headers, value }): React.Node {
  return (
    <div>
      <p className="m-3 text-xl">Week {index + 1}</p>
      <Table headers={headers} data={value} />
    </div>
  );
}

export default function Selection(props: Props): React.Node {
  const query = useQuery();
  const { i18n } = useTranslation();
  let tagId = query.get("tagId");
  const [selected, setSelected] = React.useState([]);
  (selected: TagSelection[]);
  const [timetable, setTimetable] = React.useState({});
  (timetable: Timetable);
  const headers = [
    { key: "day", name: "Day" },
    { key: "start", name: "Start Hour" },
    { key: "duration", name: "Duration" },
    { key: "teacher", name: "Teacher" },
    { key: "subject", name: "Subject" },
    { key: "type", name: "Type" },
  ];

  const table = React.useMemo(() => {
    if (timetable == null || timetable.weeks == null) return [];
    const y = timetable.weeks.map((w) => {
      const order = ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"];
      const x = order.map((day) => w[day]);
      const classes: TClass[] = x.flat();
      return classes.map((cl) => {
        let res = Object.assign({}, cl);
        let date = new Date(cl.start * 1000);
        res.start = date.toISOString().substr(11, 8);
        res.duration = cl.duration / (60 * 60) + " hours";
        return res;
      });
    });
    return y;
  }, [timetable]);

  // fetches the first tags
  React.useEffect(() => {
    setSelected([]);

    async function fetchData() {
      props.loadingBar.current.continuousStart();
      if (tagId == null) {
        getChildrenTags("")
          .then((response) => setSelected([{ tags: response.data, selected: null }]))
          .catch((error) => error.response.data.errors.forEach((e) => props.addMessage({ text: e, type: "error" })))
          .finally(() => props.loadingBar.current.complete());
        return;
      }
      getShorthands(tagId)
        .then((response) => {
          if (response.data == null) {
            props.loadingBar.current.complete();
            return;
          }
          const data = response.data;
          const childrenProm = data.shorthands.map((tag) => getChildrenTags(tag.uuid));
          getChildrenTags("").then((response) => {
            const base = response.data;
            Promise.all(childrenProm).then((response) => {
              const children = response.map((child) => child.data);
              children.splice(0, 0, base);
              const currSel = children.map((ch, index) => ({ tags: ch, selected: data.shorthands[index] }));
              setSelected(currSel);
            });
          });
        })
        .catch((error) => error.response.data.errors.forEach((e) => props.addMessage({ text: e, type: "error" })))
        .finally(() => props.loadingBar.current.complete());
    }

    let auth = firebase.auth();
    if (auth.currentUser != null) {
      fetchData();
      return;
    }
    auth.onAuthStateChanged(() => fetchData());
  }, [tagId]);

  // gets timetable when selected is changed
  React.useEffect(() => {
    async function fetchData() {
      props.loadingBar.current.continuousStart();
      if (selected.length === 0) setTimetable({});
      const last = selected.reduce(
        (accumulator, currentVal) => (currentVal.selected != null ? currentVal.selected : accumulator),
        null
      );
      if (last == null) {
        props.loadingBar.current.complete();
        return;
      }

      getTimetable(last.uuid)
        .then((response) => setTimetable(response.data))
        .catch((error) => error.response.data.errors.forEach((e) => props.addMessage({ text: e, type: "error" })))
        .finally(() => props.loadingBar.current.complete());
    }

    fetchData();
  }, [selected]);

  function getNextTag(index: number) {
    return (tag: Tag) => {
      setSelected((prevSelected) => [
        ...prevSelected.slice(0, index),
        { tags: prevSelected[index].tags, selected: tag },
      ]);
      getChildrenTags(tag.uuid)
        .then((response) => setSelected((prevSelected) => [...prevSelected, { tags: response.data, selected: null }]))
        .catch((error) => error.response.data.errors.forEach((e) => props.addMessage({ text: e, type: "error" })));
    };
  }

  function saveSelection() {
    let result = selected.reduce((acc, cv) => (cv.selected != null ? cv.selected : acc), null);
    if (result == null) return;
    addTag(result, props.tags, props.setTags);
    props.addMessage({
      text: i18n.t("timetable-saved"),
      type: "success",
    });
  }

  return (
    <div className="mx-2 md:mx-4 lg:mx-10">
      <div className="flex flex-col lg:flex-row lg:justify-between">
        <div className="flex flex-wrap flex-col md:flex-row justify-start w-full">
          {selected.map((value, index) => {
            if (value.tags.length > 0)
              return (
                <div key={"s" + index} className="w-full md:w-1/4 md:min-w-36 mr-1 mb-1">
                  <Select
                    placeholder={value.selected != null ? value.selected.name : `${i18n.t("select-category")}...`}
                    setValue={getNextTag(index)}
                    allowModify={false}
                    data={value.tags}
                  />
                </div>
              );
            return null;
          })}
        </div>
        <button
          className="bg-white px-5 py-1 rounded-3xl mt-4 lg:mt-0 focus:outline-none whitespace-nowrap w-min"
          onClick={saveSelection}
        >
          {`${i18n.t("save")} ${i18n.t("selection")}`}
        </button>
      </div>
      <div className="text-white">
        {table.length === 0 ? (
          <div className="w-full h-96 flex justify-center items-center text-center">
            <p className="text-5xl">{i18n.t("await-preview")}</p>
          </div>
        ) : (
          <div className="overflow-auto">
            {table.map((value, index) => (
              <InnerTable value={value} index={index} headers={headers} key={"t" + index} />
            ))}
          </div>
        )}
      </div>
    </div>
  );
}
