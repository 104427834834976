// @flow

import type { Tag } from "./types";

export function addTag(tag: Tag, savedTags: Tag[], setSavedTags: (any) => any): boolean {
  let result = savedTags.reduce((acc, curr) => curr.uuid === tag.uuid ? true : acc, false);
  if (!result) {
    setSavedTags([...savedTags, tag]);
    return true;
  }
  return false;
}

export function removeTag(uuid: string, savedTags: Tag[], setSavedTags: (any) => any) {
  let result = savedTags.filter(tag => tag.uuid !== uuid);
  setSavedTags(result);
}
