import React from "react";
import { Redirect } from "react-router";
import SignInFormBox from "./SignInFormBox";

export default function SignInPage(props) {
  return (
    <>
      {!props.signedIn ? (
        <div
          className="object-cover w-screen" 
          style={{
            backgroundImage: `url(images/bg.png?nf_resize=fit&w=${window.screen.availWidth})`,
            height: window.innerHeight
          }}
        >
          <div className="w-full md:w-1/2 xl:w-1/5 p-5 font-body space-y-5 mx-auto">
            <img src="images/qub_timetable_logo.svg" alt="qub timetable" className="h-28 mx-auto" />
            <SignInFormBox setSignedIn={props.setSignedIn} addMessage={props.addMessage} />
          </div>
        </div>
      ) : (
        <Redirect to="/" />
      )}
    </>
  );
}
